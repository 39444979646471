import { Risk } from "@cur8/health-risks-calc";
import { clamp } from "lib/math";
import React, { useMemo } from "react";
import { Trans } from "./trans";
import { ChartRange, MarkerData, RangeData, Variant } from "./types";

const VariantMap: Record<Risk, Variant> = {
  [Risk.Unknown]: "normal",
  [Risk.Optimal]: "normal",
  [Risk.Normal]: "normal",
  [Risk.LowRisk]: "warning",
  [Risk.Risk]: "warning",
  [Risk.ModerateRisk]: "danger",
  [Risk.HighRisk]: "danger",
  [Risk.ImmediateRisk]: "danger",
};

interface ChildrenData {
  values: MarkerData[];
  ranges: RangeData[];
}

interface RangeChartDataComposerProps {
  ranges: ChartRange[];
  value?: number;
  previousValue?: number;
  children: (data: ChildrenData) => React.ReactElement;
  scanNum: number;
}

export function RangeChartDataComposer({
  ranges,
  children,
  value,
  previousValue,
  scanNum,
}: RangeChartDataComposerProps) {
  const max = Math.max(...ranges.map((range) => range.to));
  const min = Math.min(...ranges.map((range) => range.from));

  const rangeData: RangeData[] = useMemo(
    () =>
      ranges.map((range) => ({
        from: range.from,
        to: range.to,
        label: range.label,
        variant: VariantMap[range.risk],
        width: Math.floor(((range.to - range.from) / (max - min)) * 100),
      })),
    [ranges, max, min]
  );

  const values: MarkerData[] = useMemo(() => {
    if (value === undefined) return [];

    const areValuesTheSame = previousValue === value;
    const primaryVariant = areValuesTheSame ? "primary-outlined" : "primary";
    const valueMaker: MarkerData = {
      value: clamp(value, min, max),
      variant: primaryVariant,
      label: <Trans.Scan scanNum={scanNum} />,
      key: `marker_${value}_${primaryVariant}_${scanNum}`,
    };

    const previousValueMaker: MarkerData | undefined =
      previousValue !== undefined
        ? {
            value: clamp(previousValue, min, max),
            variant: "outlined",
            label: <Trans.Scan scanNum={scanNum - 1} />,
            key: `marker_${value}_outlined_${scanNum}`,
          }
        : undefined;

    return [previousValueMaker, valueMaker].filter(
      (value): value is MarkerData => value !== undefined
    );
  }, [value, previousValue, min, max, scanNum]);

  return <>{children({ ranges: rangeData, values })}</>;
}
